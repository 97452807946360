<template>
  <div class="content_container">
    <template v-if="payMethod">
      <template v-if="!wxPaySuccess">
        <el-row class="wx">
          <el-col
            :span="24"
            class="title"
          >
            微信支付
          </el-col>
          <el-col :span='14'>
            <p class="totalMoney"> 应付金额￥<i>{{$route.query.totalMoney}}</i></p>
            <p
              class="p"
              v-if='!show'
            >二维码已过期，刷新页面重新获取二维码。</p>
            <p
              class="p"
              v-else
            >距离二维码过期还剩<span> {{codeInput}}</span> 秒，过期后请刷新页面重新获取二维码。</p>
            <div class="code">
              <div
                class="cover"
                v-if='!show'
              >

                <p @click="load">
                  获取失败 点击重新获取二维码
                </p>
              </div>
              <vue-qr
                class="ewm "
                :text="wxPayImage"
                :size="250"
                :margin="0"
              />
            </div>
            <dl>请使用微信扫一扫扫描二维码支付</dl>
          </el-col>
          <el-col :span='10'>
            <img
              src="../../assets/images/phone-bg.png"
              alt=""
            >
          </el-col>
        </el-row>
      </template>
      <template v-else>
        <div class="pay">
          <div class="border">
            <p>
              <el-button
                @click="$router.push('/')"
                block
                shape="circle"
                style="margin-top: 30px"
              >
                返回首页
              </el-button>
            </p>
            <p>
              <el-button
                @click="$router.push('/member-order')"
                block
                shape="circle"
                style="margin-top: 30px"
              >
                查看我的订单
              </el-button>
            </p>

          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="pay">
        <div class="border">
          <!-- <p>
            <el-button
              type="info"
              block
              shape="circle"
              @click="rePay"
              style="margin-top: 30px"
            >
              重新支付
            </el-button>
          </p> -->
          <p>
            <el-button
              @click="$router.push('/')"
              block
              shape="circle"
              style="margin-top: 30px"
            >
              返回首页
            </el-button>
          </p>
          <p>
            <el-button
              @click="$router.push('/member-order')"
              block
              shape="circle"
              style="margin-top: 30px"
            >
              查看我的订单
            </el-button>
          </p>

        </div>
      </div>
      <!-- <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <span>正在支付...</span>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="success"
          >支付成功</el-button>
        </span>
      </el-dialog> -->
      <div class="mine"></div>
    </template>
  </div>
</template>
<script>
import VueQr from "vue-qr";
import NavHeader from "@/components/layout/nav-header";

export default {
  components: { VueQr, NavHeader },
  data() {
    return {
      payMethod: "",
      wxPayImage: "",
      wxPaySuccess: false,
      dialogVisible: false,
      show: true,
      time: 60,
      codeInput: ""
    };
  },
  computed: {},
  mounted() {
    if (this.$route.query.payway == "wx") {
      this.payMethod = "wx";
      this.timer();
    }
    if (this.$route.query.order_master_id || this.$route.query.order_id) {
      this.$get(
        "/home/order/send/pay",
        {
          order_id: this.$route.query.order_master_id,
          type: "pc",
          payway: this.payMethod,
          member_id: this.$store.state.member.id
        },
        {},
        2,
        1,
        true
      ).catch(res => {
        if (res) {
          if (!this.payMethod) {
            const div = document.querySelector(".mine");
            div.innerHTML = res;
            document.forms.alipaysubmit.setAttribute("target", "_blank");
            document.forms.alipaysubmit.submit();
            this.showDialog();
            return;
          }
          this.wxPayImage = res;
          this.interval = setInterval(() => {
            this.getPay();
          }, 3000);
        }
      });
    }
  },
  methods: {
    getPay() {
      this.$get("home/order/show/pay", {
        payway: this.payMethod,
        order_id: this.$route.query.order_master_id,
        type: "pc",
        member_id: this.$store.state.member.id
      }).then(res => {
        if (res.order_status_id == 13) {
          this.$alert("支付成功！");
          this.wxPaySuccess = true;
          clearInterval(this.interval);
        }
      });
    },
    load() {
      location.reload();
    },
    //1分钟倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.codeInput = this.time;
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;

        this.show = false;
      }
    },

    rePay() {
      document.forms["alipaysubmit"].submit();
      this.showDialog();
    },
    showDialog() {
      this.dialogVisible = true;
    },
    success() {
      this.dialogVisible = false;
      this.$router.push("/member-order");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins";
.totalMoney {
  text-align: right;
  background: #fff;
  @include center;
  height: 40px;
  margin-bottom: 10px;

  i {
    color: red;
    font-size: 18px;
    font-weight: bold;
  }
}
.wx {
  .code {
    width: 250px;
    height: 250px;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    .cover {
      cursor: pointer;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 999;
      left: 0;
      bottom: 0;

      background: rgba(0, 0, 0, 0.5);
      @include center;
      p {
        width: 80%;
        line-height: 30px;
        background: #fff;
      }
    }
  }
  .p {
    margin-bottom: 20px;
    span {
      font-size: 14px;
      font-weight: bold;
      color: #e50232;
    }
  }
  .title {
    padding-bottom: 30px;
    font-size: 22px;
    font-weight: bold;
  }

  .el-col:nth-child(3) {
    img {
      height: 400px;
    }
  }
  dl {
    margin-top: 20px;
    font-size: 16px;
    color: #f56c6c;
  }

  width: 1226px;
  padding: 50px 100px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 50px;
  background: #fff;
  text-align: center;
}
.pay {
  padding: 20px;
  border-radius: 20px;
  width: 70%;
  margin: 50px auto;
  height: 400px;
  background: #fff;
  .border {
    border-radius: 20px;
    text-align: center;
    width: 100%;
    // border: 2px solid #fba883 !important;
    .el-button {
      width: 40%;
      margin: 10px auto;
    }
  }
}
.beizhu {
  dd:nth-child(1) {
    vertical-align: middle;
    span {
      display: block;
      width: 80px;
      line-height: 40px;
    }
    @include row;
  }
  @include space-between;
}
.count {
  text-align: right;
}
.pay {
  @include row;
}
.cont {
  margin-bottom: 10px;
  padding: 10px 0;
  .title {
    line-height: 40px;
    font-weight: bold;
  }
  dl {
    padding: 5px;
    @include space-between;
  }
  .list {
    padding: 5px;
  }
}
.shop_name {
  @include row;
  line-height: 35px;
  height: 35px;
  vertical-align: middle;
  font-weight: bold;
}
.Promotion {
  margin-top: 30px;
}

.cart {
  margin: 0 auto;
  width: 1226px;

  input[type="checkbox"] {
    width: 15px;
    height: 15px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 15px;
    position: relative;
    margin-left: 5px;
  }

  input[type="checkbox"]::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    border: 1px solid #d9d9d9;
  }

  input[type="checkbox"]:checked::before {
    content: "\2713";
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    color: #e50232;
    font-size: 13px;
    font-weight: bold;
  }

  .top {
    height: 95px;
    position: relative;

    // line-height: 95px;
    img {
      height: 45px;
      width: 140px;
      float: left;
      margin-top: 25px;
    }

    h1 {
      float: left;
      font-size: 20px;
      line-height: 95px;
    }

    .Search_Goods {
      margin-left: 590px;
      position: absolute;
      top: 28px;
    }
  }

  .allGood {
    ul {
      height: 35px;
      border-bottom: 2px solid #e6e6e6;

      li {
        height: 100%;
        width: 100px;
        color: #f40;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        border-bottom: 2px solid #f40;
      }
    }
  }

  .list_product {
    width: 100%;
    min-height: 70px;
    @include align-center;
    .list-goods-name {
      font-weight: bold;
      @include space-between;
      img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        border: 1px solid #999;
      }
      p {
        flex: 1;
        line-height: 18px;
      }
    }
  }

  .list_product * {
    display: inline-block;
    vertical-align: middle;
  }

  .list_product li {
    font-size: 10px;
    font-weight: 100;
  }

  .car_list {
    width: 100%;

    .first {
      @include space-between;

      width: 100%;
      box-sizing: border-box;
      height: 50px;
      line-height: 50px;
    }

    .first li:nth-child(1) {
      flex: 1;
    }
    .first li:nth-child(2) {
      width: 15%;
      text-align: center;
    }
    .first li:nth-child(3) {
      width: 10%;
      text-align: center;
    }
    .first li:nth-child(4) {
      text-align: center;
      width: 15%;
    }
    .first li:nth-child(5) {
      text-align: center;
      width: 15%;
    }

    .center_tr {
      height: 130px;
      width: 1188;
      border: 1px solid #ccc;
      margin-bottom: 15px;
      padding: 17px 0 0 0;

      li {
        float: left;
      }
    }

    .center_tr li:nth-child(1) {
      width: 60px;

      input {
        margin: 0 10px 5px 15px;
      }
    }

    .center_tr li:nth-child(2) {
      width: 342px;

      img {
        width: 80px;
        height: 80px;
        float: left;
      }

      span:hover {
        text-decoration: underline;
        color: #f40;
        cursor: pointer;
        float: left;
      }
    }

    .center_tr li:nth-child(3) {
      width: 252px;

      p {
        margin-bottom: 5px;

        span {
          font-size: 12px;
        }
      }

      // text-align: center;
      // width: 359px;
      // padding-left: 220px;
      // color: #3c3c3c;
      // font-weight: 700;
      // font-family: Verdana,Tahoma,arial;
    }

    .center_tr li:nth-child(4) {
      text-align: center;
      width: 107px;
      color: #3c3c3c;
      font-weight: 700;
      font-family: Verdana, Tahoma, arial;
    }

    .center_tr li:nth-child(5) {
      text-align: center;
      width: 120px;

      .el-input-number {
        width: 80px;
        // color:#222;
      }
    }

    .center_tr li:nth-child(6) {
      text-align: center;
      color: #f40;
      font-weight: 700;
      width: 138px;
      font-family: Verdana, Tahoma, arial;
      padding-top: 3px;
    }

    .center_tr li:nth-child(7) {
      text-align: center;
      width: 149px;
      padding-left: 30px;

      span:hover {
        text-decoration: underline;
        color: #f40;
        cursor: pointer;
      }
    }
  }

  .list_shop {
    min-height: 130px;
    width: 100%;
    box-sizing: border-box;
    background: #f7f7f7;
    margin-bottom: 15px;
    padding: 10px;
    display: inline-block;
  }

  .last_tr {
    text-align: right;
    height: 50px;
    line-height: 50px;
    background: #e5e5e5;

    li {
      margin-left: 30px;
      display: inline-block;
      height: 50px;
      line-height: 50px;
    }
  }

  .checked_none {
    height: 100%;
    width: 150px;
    text-align: center;
    background: #b0b0b0;
    cursor: not-allowed;
    color: #fff;
    font-size: 20px;
  }

  .checked_block {
    height: 100%;
    width: 150px;
    text-align: center;
    background: #f22d00;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }

  .last_tr li {
    height: 100%;

    p {
      float: left;
    }

    .tr_p2 {
      width: 150px;
      font-weight: 700;
      font-size: 22px;
      color: #ff0036;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.el-input__inner {
  padding: 0 !important;
  text-indent: 0px !important;
  border-radius: 0 !important;
}

.el-input-number__decrease {
  width: 20px !important;
}

.el-input-number__increase {
  width: 20px !important;
}
</style>
 